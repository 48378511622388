import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
    Container,
    Row,
    Col,
    Card,
    Form,
    FormCheck,
    Button,
    Modal
} from "react-bootstrap";

import "./Donations.scss";
import { useLocation } from "react-router";

const Donations = ({ props }) => {
    const PostForm = useRef();
    const [donation, setDonation] = useState("3");
    const [custom, setCustom] = useState("");
    const [showModal, setShowModal] = useState(false);
    const location = useLocation();

    React.useEffect(() => {
        if (location.search.includes("?&orderId=")) {
            fetch(
                "https://tfh-serverless.netlify.app/.netlify/functions/get-order-id",
                {
                    method: "POST",
                    body: JSON.stringify({
                        orderId: location.search.split("?&orderId=")[1]
                    })
                }
            )
                .then(res => res.json())
                .then(response => {
                    if (response.rowElement) {
                        toast.success(response.rowElement.status);
                    } else {
                        toast.error("Comanda negasita");
                    }
                })
                .catch(err => console.log(err));
        }
    }, []);

    const submit = amount => {
        fetch("https://tfh-serverless.netlify.app/.netlify/functions/donate", {
            method: "POST",
            body: JSON.stringify({ amount })
        })
            .then(res => res.json())
            .then(data => {
                PostForm.current.action = "https://secure.mobilpay.ro";
                for (const [key, value] of Object.entries(data)) {
                    console.log(key, value);
                    const input = document.createElement("input");
                    input.type = "hidden";
                    input.name = key === "envKey" ? "env_key" : "data";
                    input.value = value;
                    PostForm.current.append(input);
                }
                PostForm.current.submit();
            });
    };

    const handleDonation = ev => {
        if (ev.target.value !== "other") {
            setDonation(ev.target.value);
        } else {
            setDonation("");
        }
    };

    const handleCustom = ev => {
        setCustom(ev.target.value);
    };

    const handleModal = () => {
        if (custom) {
            submit(custom * 5);
        } else if (donation) {
            submit(donation * 5);
        } else {
            setShowModal(!showModal);
        }
    };

    const { t } = useTranslation();

    React.useEffect(() => {
        const script = document.createElement("script");

        script.src = "https://formular230.ro/share/1e689b086a";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <section className="Donations">
            <form
                ref={PostForm}
                name={"PostForm"}
                id={"PostForm"}
                method="POST"
            />
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="title">
                            <h2>{t("donate.entry")}</h2>
                            <h1>{t("donate.title")}</h1>
                            <p>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t("donate.subtitle")
                                    }}
                                />
                            </p>
                        </div>
                    </Col>
                </Row>

                <Row className="middle-md">
                    <Col xs={12} lg={6}>
                        <div className="donate-form">
                            <Card>
                                <Card.Body>
                                    <Card.Text>
                                        <span className="h5">
                                            {t("donate.form.title")}
                                        </span>
                                        <span>{t("donate.form.subtitle")}</span>
                                    </Card.Text>

                                    <div className="radio-sum">
                                        <Form.Check inline={true}>
                                            <FormCheck.Input
                                                type="radio"
                                                value="3"
                                                id="donation-3"
                                                checked={donation === "3"}
                                                onChange={handleDonation}
                                            />
                                            <FormCheck.Label
                                                htmlFor="donation-3"
                                                className={
                                                    donation === "3"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                3 Euro
                                            </FormCheck.Label>
                                        </Form.Check>

                                        <Form.Check inline={true}>
                                            <FormCheck.Input
                                                type="radio"
                                                value="5"
                                                id="donation-5"
                                                checked={donation === "5"}
                                                onChange={handleDonation}
                                            />
                                            <FormCheck.Label
                                                htmlFor="donation-5"
                                                className={
                                                    donation === "5"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                5 Euro
                                            </FormCheck.Label>
                                        </Form.Check>

                                        <Form.Check inline={true}>
                                            <FormCheck.Input
                                                type="radio"
                                                value="10"
                                                id="donation-10"
                                                checked={donation === "10"}
                                                onChange={handleDonation}
                                            />
                                            <FormCheck.Label
                                                htmlFor="donation-10"
                                                className={
                                                    donation === "10"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                10 Euro
                                            </FormCheck.Label>
                                        </Form.Check>

                                        <Form.Check inline={true}>
                                            <FormCheck.Input
                                                type="radio"
                                                value="20"
                                                id="donation-20"
                                                checked={donation === "20"}
                                                onChange={handleDonation}
                                            />
                                            <FormCheck.Label
                                                htmlFor="donation-20"
                                                className={
                                                    donation === "20"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                20 Euro
                                            </FormCheck.Label>
                                        </Form.Check>

                                        <Form.Check inline={true}>
                                            <FormCheck.Input
                                                type="radio"
                                                value="50"
                                                id="donation-50"
                                                checked={donation === "50"}
                                                onChange={handleDonation}
                                            />
                                            <FormCheck.Label
                                                htmlFor="donation-50"
                                                className={
                                                    donation === "50"
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                50 Euro
                                            </FormCheck.Label>
                                        </Form.Check>

                                        <Form.Check inline={true}>
                                            <FormCheck.Input
                                                type="radio"
                                                value="other"
                                                id="donation-other"
                                                checked={donation === ""}
                                                onChange={handleDonation}
                                            />
                                            <FormCheck.Label
                                                htmlFor="donation-other"
                                                className={
                                                    donation === ""
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                +
                                            </FormCheck.Label>
                                        </Form.Check>

                                        {!donation && (
                                            <Form.Check inline={true}>
                                                <Form.Control
                                                    type="number"
                                                    className="custom"
                                                    value={custom}
                                                    onChange={handleCustom}
                                                    placeholder="Ex. 100"
                                                />
                                            </Form.Check>
                                        )}
                                    </div>

                                    <Button
                                        className="mt-4"
                                        variant="secondary"
                                        block={true}
                                        onClick={handleModal}
                                    >
                                        {t("donate.form.button.text")}
                                    </Button>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>

                    <Col xs={12} lg={6}>
                        <div className="bank-donations">
                            <h4 className="font-weight-bold">
                                {t("donate.ong_data.title")}
                            </h4>
                            <p>{t("donate.ong_data.subtitle")}</p>
                            <br />
                            <p>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t("donate.ong_data.name")
                                    }}
                                />
                            </p>
                            <p>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t("donate.ong_data.raf")
                                    }}
                                />
                            </p>
                            <p>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t("donate.ong_data.cif")
                                    }}
                                />
                            </p>
                            <p>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t("donate.ong_data.address")
                                    }}
                                />
                            </p>
                            <p>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t("donate.ong_data.iban")
                                    }}
                                />
                            </p>
                            <div>
                                <span>
                                    <form action="https://www.paypal.com/donate" method="post" target="_blank" style={{display: "inline"}}>
                                    <input type="hidden" name="hosted_button_id" value="YX94LKEMRVTCU" />
                                    <button type="submit" className="mt-4 btn btn-secondary btn-block">{t("donate.paypal.button_text")}</button>
                                    <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                                    </form>
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <h4 className="font-weight-bold mt-4">
                            {t("donate.redirect.title")}
                        </h4>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <h5 className="text-primary mt-4">
                            {t("donate.redirect.what")}
                        </h5>
                        <p>{t("donate.redirect.details")}</p>

                        <h5 className="text-primary mt-5">
                            {t("donate.redirect.how")}
                        </h5>

                        <div className="step">
                            <div className="number">1</div>
                            <div className="content">
                                <p>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                "donate.redirect.step.one"
                                            )
                                        }}
                                    />
                                </p>
                            </div>
                        </div>

                        <div className="step">
                            <div className="number">2</div>
                            <div className="content">
                                <p>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                "donate.redirect.step.two"
                                            )
                                        }}
                                    />
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Modal
                show={showModal}
                onHide={handleModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered={true}
                keyboard={false}
                backdrop="static"
                size="lg"
                className="modal-dark"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("donate.modal.title")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <p>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: t("donate.modal.content")
                                        }}
                                    />
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="submit"
                        variant="secondary"
                        onClick={handleModal}
                    >
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
};

export default Donations;
