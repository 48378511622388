import React from 'react';

import {Col, Container, Row} from 'react-bootstrap';
import {Element, Link}       from 'react-scroll';
import {useTranslation}      from 'react-i18next';

import Footer     from '../../components/footer';
import PageHeader from '../../components/page-header';

import './Policy.scss';


const Policy = () => {
    const {t} = useTranslation();

    return (
        <section className="Policy">
            <PageHeader/>

            <Container>
                <Row>
                    <Col xs={12}>
                        <h2>{t('policy.title')}</h2>

                        <br/>

                        <span dangerouslySetInnerHTML={{__html: t('policy.intro')}}/>

                        <p className="pink-600"><strong>{t('policy.nav.title')}</strong></p>
                        <ul className="policy-nav">
                            <li>
                                <Link to="what-data" offset={-130} smooth={true}>{t('policy.nav.what_data')}</Link>
                            </li>
                            <li>
                                <Link to="minor-data" offset={-130} smooth={true}>{t('policy.nav.minor_data')}</Link>
                            </li>
                            <li>
                                <Link to="special-data" offset={-130}
                                      smooth={true}>{t('policy.nav.special_data')}</Link>
                            </li>
                            <li>
                                <Link to="for-what" offset={-130} smooth={true}>{t('policy.nav.for_what')}</Link>
                            </li>
                            <li>
                                <Link to="to-whom" offset={-130} smooth={true}>{t('policy.nav.to_whom')}</Link>
                            </li>
                            <li>
                                <Link to="third-party" offset={-130} smooth={true}>{t('policy.nav.third_party')}</Link>
                            </li>
                            <li>
                                <Link to="data-transfer" offset={-130}
                                      smooth={true}>{t('policy.nav.data_transfer')}</Link>
                            </li>
                            <li>
                                <Link to="other-person-data" offset={-130}
                                      smooth={true}>{t('policy.nav.other_person_data')}</Link>
                            </li>
                            <li>
                                <Link to="data-time-storage" offset={-130}
                                      smooth={true}>{t('policy.nav.data_time_storage')}</Link>
                            </li>
                            <li>
                                <Link to="rights" offset={-130} smooth={true}>{t('policy.nav.rights')}</Link>
                            </li>
                            <li>
                                <Link to="data-safety" offset={-130} smooth={true}>{t('policy.nav.data_safety')}</Link>
                            </li>
                            <li>
                                <Link to="external-links" offset={-130}
                                      smooth={true}>{t('policy.nav.external_links')}</Link>
                            </li>
                            <li>
                                <Link to="questions" offset={-130} smooth={true}>{t('policy.nav.questions')}</Link>
                            </li>
                            <li>
                                <Link to="policy-changes" offset={-130}
                                      smooth={true}>{t('policy.nav.policy_changes')}</Link>
                            </li>
                        </ul>
                        <hr/>

                        <Element name="what-data">
                            <span dangerouslySetInnerHTML={{__html: t('policy.element.what_data')}}/>
                        </Element>

                        <hr/>

                        <Element name="minor-data">
                            <span dangerouslySetInnerHTML={{__html: t('policy.element.minor_data')}}/>
                        </Element>

                        <hr/>

                        <Element name="special-data">
                            <span dangerouslySetInnerHTML={{__html: t('policy.element.special_data')}}/>
                        </Element>

                        <hr/>

                        <Element name="for-what">
                            <span dangerouslySetInnerHTML={{__html: t('policy.element.for_what')}}/>
                        </Element>

                        <hr/>

                        <Element name="to-whom">
                            <span dangerouslySetInnerHTML={{__html: t('policy.element.to_whom')}}/>
                        </Element>

                        <hr/>

                        <Element name="third-party">
                            <span dangerouslySetInnerHTML={{__html: t('policy.element.third_party')}}/>
                        </Element>

                        <hr/>

                        <Element name="data-transfer">
                            <span dangerouslySetInnerHTML={{__html: t('policy.element.data_transfer')}}/>
                        </Element>

                        <hr/>

                        <Element name="other-person-data">
                            <span dangerouslySetInnerHTML={{__html: t('policy.element.other_person_data')}}/>
                        </Element>

                        <hr/>

                        <Element name="data-time-storage">
                            <span dangerouslySetInnerHTML={{__html: t('policy.element.data_time_storage')}}/>
                        </Element>

                        <hr/>

                        <Element name="rights">
                            <span dangerouslySetInnerHTML={{__html: t('policy.element.rights')}}/>
                        </Element>

                        <hr/>

                        <Element name="data-safety">
                            <span dangerouslySetInnerHTML={{__html: t('policy.element.data_safety')}}/>
                        </Element>

                        <hr/>

                        <Element name="external-links">
                            <span dangerouslySetInnerHTML={{__html: t('policy.element.external_links')}}/>
                        </Element>

                        <hr/>

                        <Element name="questions">
                            <span dangerouslySetInnerHTML={{__html: t('policy.element.question')}}/>
                        </Element>

                        <hr/>

                        <Element name="policy-changes">
                            <span dangerouslySetInnerHTML={{__html: t('policy.element.policy_changes')}}/>
                        </Element>
                    </Col>
                </Row>
            </Container>

            <Footer/>
        </section>
    );
};

export default Policy;
