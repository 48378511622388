import React from 'react';

import ScrollToTop from '../../components/scroll-to-top';

import './App.scss';


const App = props => {
    return (
        <section className="App">
            {props.children}

            <ScrollToTop/>
        </section>
    );
};

export default App;
