require('./bootstrap');

import React    from 'react';
import {render} from 'react-dom';

import {I18nextProvider} from 'react-i18next';
import {Provider}        from 'react-redux';

import i18n        from './i18n';
import Routes      from './routes';
import store       from './store';
import * as action from './store/actions';

import 'react-toastify/dist/ReactToastify.css';

store.dispatch(action.langCheck());

render(
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <Routes/>
        </I18nextProvider>
    </Provider>,
    document.getElementById('app')
);
