import React from 'react';

import {Col, Container, Row} from 'react-bootstrap';
import {useTranslation}      from 'react-i18next';

import Footer     from '../../components/footer';
import PageHeader from '../../components/page-header/PageHeader';

import './AnnualReports.scss';


const AnnualReports = props => {
    const {t} = useTranslation();

    return (
        <section className="AnnualReports">
            <PageHeader/>

            <Container>
                <Row>
                    <Col xs={12}>
                        <h3>{t('reports.title')}</h3>
                        <p>
                            <a target="_blank" href="/web/pdf/raport_activitate_2020_final.pdf">{t('reports.activity2020')}</a>
                        </p>
                        <p>
                            <a target="_blank" href="/web/pdf/raport_tfh_2021_final.pdf">{t('reports.activity2021')}</a>
                        </p>
                    </Col>
                </Row>
            </Container>

            <Footer/>
        </section>
    );
};

export default AnnualReports;
