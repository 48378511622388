import React, {useCallback, useEffect, useState} from 'react';

import {Container, Nav, NavDropdown, Dropdown, NavItem, Link, Navbar, Row} from 'react-bootstrap';
import {Link as RouterLink}          from 'react-router-dom';

import {scroller}      from 'react-scroll';
import {animateScroll} from 'react-scroll/modules';

import Contact          from '../contact';
import Icon             from '../icon';
import LanguageSelect   from '../language-select';
import useEventListener from '../../hooks/useEventListener';

import './Navigation.scss';


const Navigation = props => {
    const [open, setOpen]               = useState(false);
    const [showDropdown, setShowDropdown]               = useState(false);
    const [scroll, setScroll]           = useState(0);
    const [top, setTop]                 = useState(0);
    const [showContact, setShowContact] = useState(false);
    const [expanded, setExpanded]       = useState(false);

    useEffect(() => {
        animateScroll.scrollToTop();
    }, []);

    const handler = useCallback(() => {
        setScroll(window.scrollY);
    }, [setScroll]);

    useEventListener('scroll', handler);

    useEffect(
        () => {
            const el = document.querySelector('nav');

            if (el) {
                setTop(el.offsetTop);

                if (scroll > top) {
                    document.body.className = 'nav-fixed';
                } else {
                    document.body.style.paddingTop = null;
                    document.body.className        = '';
                }
            }
        },
        [handler, scroll, top]
    );

    const closeNav = () => {
        const browserWidth = getWidth();

        if (browserWidth < 992) {
            setOpen(!open);
            setExpanded(!expanded);
        }
    };

    const showNavDropdown = () => {
        setShowDropdown(true);
    }

    const hideNavDropdown = () => {
        setShowDropdown(false);
    }

    const isFixed = () => {
        if (scroll > top) {
            return 'fixed-top';
        }
    };

    const navigationCallback = () => {
        closeNav();
    };

    // const openContact = (ev: MouseEvent<HTMLAnchorElement>): void => {
    //     ev.preventDefault();
    //     closeNav();
    //     setShowContact(true);
    // };

    const closeContact = () => setShowContact(false);

    const scrollToElement = (element, offset = 0) => ev => {
        ev.preventDefault();

        const browserWidth = getWidth();

        if (browserWidth < 768 && element === 'implica-te') {
            offset = 100;
        }

        closeNav();

        scroller.scrollTo(element, {
            smooth : true,
            hashSpy: true,
            offset
        });
    };

    const getWidth = () => {
        return Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );
    };

    const {t} = props;

    return (
        <section className="Navigation">
            <Navbar
                collapseOnSelect={true}
                expand="lg"
                expanded={expanded}
                onToggle={navigationCallback}
                className={isFixed()}
            >
                <Container>
                    <Navbar.Brand href="/">
                        <img
                            src="/web/img/logo/logo.svg"
                            alt="Translating For Humanity"
                        />
                    </Navbar.Brand>

                    <LanguageSelect show="mobile"/>

                    <Navbar.Toggle
                        aria-controls="responsive-navbar-nav"
                    >
                        <div className={'nav-burger' + (open ? ' show' : '')}>
                            {!open ? (
                                <Icon size="light" icon="bars"/>
                            ) : (
                                 <Icon size="light" icon="times"/>
                             )}
                        </div>
                    </Navbar.Toggle>

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Container>
                            <Row>
                                <Nav className="mr-auto ml-auto">
                                    <Nav.Link
                                        onClick={scrollToElement('acasa')}
                                    >
                                        {t('nav.home')}
                                    </Nav.Link>
                                    <div className="aboutus-dropdown">
                                        <Nav.Link
                                            onClick={scrollToElement('despre-noi')}
                                            onMouseEnter={showNavDropdown}
                                            onMouseLeave={hideNavDropdown}
                                        >
                                            {t('nav.about')}
                                        </Nav.Link>
                                        <NavDropdown show={showDropdown} onMouseEnter={showNavDropdown} onMouseLeave={hideNavDropdown}>
                                            <NavDropdown.Item href={(props.lang === 'en' ? `/en/echipa` : '/echipa')}>{t('footer.nav.team')}</NavDropdown.Item>
                                        </NavDropdown>
                                    </div>
                                    <Nav.Link
                                        onClick={scrollToElement('implica-te', 20)}
                                    >
                                        {t('nav.involve')}
                                    </Nav.Link>
                                    <Nav.Link
                                        onClick={scrollToElement('doneaza', 20)}
                                    >
                                        {t('nav.donate')}
                                    </Nav.Link>
                                    <RouterLink to={(props.lang === 'en' ? `/en/contact` : '/contact')} className="nav-link">
                                        {t('nav.contact')}
                                    </RouterLink>
                                </Nav>
                            </Row>
                        </Container>
                    </Navbar.Collapse>

                    <LanguageSelect show="desktop"/>
                </Container>
            </Navbar>

            <Contact show={showContact} onHide={closeContact}/>
        </section>
    );
};

export default Navigation;
