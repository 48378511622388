import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "./Beneficiaries.scss";

const Beneficiaries = () => {
    const { t } = useTranslation();

    return (
        <section className="Beneficiaries">
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="title">
                            <h2>{t("beneficiaries.entry")}</h2>
                            <h1>{t("beneficiaries.title")}</h1>
                            <p>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t("beneficiaries.subtitle")
                                    }}
                                />
                            </p>
                        </div>
                    </Col>
                </Row>

                <Row className="middle-md logos">
                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://susinima.eu/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/beneficiaries/sus-inima.png"
                                alt="Sus Inima"
                            />
                        </a>
                    </Col>

                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://asociatiamagic.ro/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/beneficiaries/asociatia-magic.png"
                                alt="Asociația Magic"
                            />
                        </a>
                    </Col>

                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://www.magichelp.ro/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/beneficiaries/magic-help.png"
                                alt="Magic Help"
                            />
                        </a>
                    </Col>

                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://opensourcemedicalsupplies.org/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/beneficiaries/oscms.png"
                                alt="Open Source Medical Supplies"
                            />
                        </a>
                    </Col>
                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://www.daruiestearipi.ro/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/beneficiaries/daruieste-aripi.png"
                                alt="Daruieste Aripi"
                            />
                        </a>
                    </Col>
                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://funky.ong/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/beneficiaries/funky.png"
                                alt="Funky Citizens"
                            />
                        </a>
                    </Col>
                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://www.asociatiafiiomegratis.ro"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/beneficiaries/asoc-fii-om.jpg"
                                alt="FiiOmEGratis"
                            />
                        </a>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Beneficiaries;
