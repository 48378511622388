import {getLocale} from '../../utils/locale';

import * as ActionTypes from '../action-types';


const check = state => {
    const localLocale = getLocale();

    state = {
        ...state,
        lang: localLocale
    };

    return state;
};

const set = (state, payload = {}) => {
    localStorage.setItem('lang', payload.lang);

    state = {
        ...state,
        lang: payload.lang,
    };

    return state;
};

const Lang = (state = {lang: 'ro'}, {type, payload = null}) => {
    switch (type) {
        case ActionTypes.LANG_CHECK:
            return check(state);
        case ActionTypes.LANG_SET:
            return set(state, payload);

        default:
            return state;
    }
};

export default Lang;
