import React from 'react';

import {Button, Card, Col, Container, Modal, Row} from 'react-bootstrap';
import {useTranslation}      from 'react-i18next';

import Footer     from '../../components/footer';
import PageHeader from '../../components/page-header';

import './Team.scss';


const Team = props => {
    const {t} = useTranslation();

    return (
        <section className="Team">
            <PageHeader/>

            <Container>
                <Row>
                    <Col xs={12}>
                        <h3>Echipa Translating for Humanity</h3>
                    </Col>
                </Row>
                <Row className="middle-md">
                    <Col xs={12} md={4}>
                        <Card
                            className="membru_echipa"
                        >
                            <Card.Body>
                                <Card.Img src="/web/img/team/laura_calin.png" />
                                <Card.Title>Laura Călin</Card.Title>
                                <Card.Subtitle>Fondator și Președinte</Card.Subtitle>
                                <Card.Text>Cu o bogată experienţă în vânzări & marketing și aproape un deceniu petrecut în Asia, Laura a fost nominalizată în topul „Cei mai de succes români în străinătate” de către Radio China International. Laura este un antreprenor în serie, sinolog și traducător de limba chineză, iar în timpul liber îi place să creeze cărţi și muzică pentru copii.</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col xs={12} md={4}>
                        <Card
                            className="membru_echipa"
                        >
                            <Card.Body>
                                <Card.Img src="/web/img/team/gabriela_stefan.png" />
                                <Card.Title>Gabriela Ștefan</Card.Title>
                                <Card.Subtitle>Director Executiv</Card.Subtitle>
                                <Card.Text>Povestea Gabrielei, a „prafului de stele” care, asemeni lui Peter Pan, i-a purtat aripile spre minunata „Ţară de Nicăieri” în care „s-a jucat serios şi pe rând” de-a televiziunea, de-a ONG-ul, de-a PR-ul şi Marketingul, poate fi echivalentul a ceea ce înseamnă să creezi, să trăieşti valoros experienţa profesională, dar mai ales...
                                    să dăruieşti.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col xs={12} md={4}>
                        <Card
                            className="membru_echipa"
                        >
                            <Card.Body>
                                <Card.Img src="/web/img/team/madalina_anghel.png" />
                                <Card.Title>Mădălina Anghel</Card.Title>
                                <Card.Subtitle>HR Manager</Card.Subtitle>
                                <Card.Text>Mădălina este un HR Manager care vine cu experienţa din zona corporate şi care crede cu tărie în importanţa dialogului şi a creării unui mediu pozitiv în care oamenii să se poată desfăşura şi să îşi atingă potenţialul maxim. Mădălina are plăcerea de a fi una dintre persoanele care “caută” voluntari, dar şi unul dintre oamenii care se ocupă ca organizarea internă a Asociaţiei să fie cât mai eficientă şi productivă.</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col xs={12} md={4}>
                        <Card
                            className="membru_echipa"
                        >
                            <Card.Body>
                                <Card.Img src="/web/img/team/emilia_furduiu.png" />
                                <Card.Title>Emilia Furduiu</Card.Title>
                                <Card.Subtitle>Designer Grafic</Card.Subtitle>
                                <Card.Text>Emilia s-a alăturat echipei pentru a crea grafica pentru print, web & social media, cu scopul de-a întări imaginea identității de brand a Asociației. Pune în munca ei creativitate, pasiune și atenție la detalii și se ghidează după principiul „Work is love made visible”.</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col xs={12} md={4}>
                        <Card
                            className="membru_echipa"
                        >
                            <Card.Body>
                                <Card.Img src="/web/img/team/iustina_gherca.png" />
                                <Card.Title>Iustina Ghercă</Card.Title>
                                <Card.Subtitle>Social Media Manager</Card.Subtitle>
                                <Card.Text>Având o experienţă de peste 5 ani în digital marketing, Iustina este Social Media Manager pentru Translating for Humanity. Îi plac în egală măsură şi cifrele, cât şi literele. Este omul care lasă lucrurile să curgă firesc şi se adaptează pe parcurs la ce-i aduce viaţa.</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col xs={12} md={4}>
                        <Card
                            className="membru_echipa"
                        >
                            <Card.Body>
                                <Card.Img src="/web/img/team/andreea_matara.png" />
                                <Card.Title>Andreea Matara</Card.Title>
                                <Card.Subtitle>Social Media Manager</Card.Subtitle>
                                <Card.Text>Este pasionată de tot ce ţine de Social Media, Video Editing şi fotografie, mereu dornică de noi provocări şi oportunităţi prin care să îşi pună creativitatea la încercare. Fun fact: Andreea este posesoarea unui Harvard University Certificate în 18th-Century Opera cu specializare în operele lui Mozart şi Handle.</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>

            <Footer/>
        </section>
    );
};

export default Team;
