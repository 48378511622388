import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "./Partners.scss";

const Partners = () => {
    const { t } = useTranslation();

    return (
        <section className="Partners">
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="title">
                            <h2>{t("partners.entry")}</h2>
                            <h1>{t("partners.title")}</h1>
                            <p>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t("partners.subtitle")
                                    }}
                                />
                            </p>
                        </div>
                    </Col>
                </Row>

                <Row className="middle-md logos">
                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://www.techhub.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/partners-logo/techhub.png"
                                alt="The global community for
                                tech entrepreneurs & startups"
                            />
                        </a>
                    </Col>

                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://www.lzagroup.ro/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/partners-logo/lzagroup-color.png"
                                alt="LZA Group SRL"
                            />
                        </a>
                    </Col>

                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://thebug.ro"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/partners-logo/thebug-color.png"
                                alt="The Bug Software Development & Consulting"
                            />
                        </a>
                    </Col>


                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://www.7000.org/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/partners-logo/7000-languages-logo.png"
                                alt="7000 LANGUAGES"
                            />
                        </a>
                    </Col>

                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://iom.by/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/partners-logo/iom-regular-logo.png"
                                alt="International Organization for Migration (IOM)"
                            />
                        </a>
                    </Col>
                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://www.outboxweb.ro"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/partners-logo/logo-outboxweb.JPG"
                                alt="outboxweb"
                            />
                        </a>
                    </Col>
                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://mercury360.ro"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/partners-logo/logo-Mercury 360.JPG"
                                alt="mercury360"
                            />
                        </a>
                    </Col>
                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://respondcrisistranslation.org
"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/partners-logo/Respond-Crisis-Translation.JPG"
                                alt="RespondCrisisTranslation"
                            />
                        </a>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Partners;
