const reducer = (state = {}, {type, payload = null}) => {
    if (type === 'persist/REHYDRATE') {
        return persistStore(state, payload);
    } else {
        return state;
    }
};

function persistStore (state, payload) {
    return {...state, payload};
}

export default reducer;
