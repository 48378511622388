import {connect} from 'react-redux';

import PageHeader from './PageHeader';

const mapStateToProps = state => {
    return {
        lang: state.lang.lang,
    };
};

export default connect(mapStateToProps)(PageHeader);
