import {connect}      from 'react-redux';

import Footer         from './Footer';

const mapStateToProps = state => {
    return {
        lang: state.lang.lang,
    };
};

export default connect(mapStateToProps)(Footer);
