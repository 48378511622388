import AnnualReports from '../pages/annual-reports/AnnualReports';
import Contact       from '../pages/contact';
import Home          from '../pages/home';
import Media         from '../pages/media/Media';
import Team         from '../pages/team/Team';
import NoMatch       from '../pages/no-match';
import Policy        from '../pages/policy';
import {getLocale}   from '../utils/locale';

const locale = getLocale();

const setLocalePath = path => {
    if (locale === 'en') {
        return `/${locale}${path}`;
    }

    return path;
};

const routes = [
    // ----------------------------------------------------------------------------
    // Pages
    // ----------------------------------------------------------------------------
    {
        path     : setLocalePath('/'),
        exact    : true,
        component: Home
    },

    {
        path     : setLocalePath('/politica-de-confidentialitate'),
        exact    : true,
        component: Policy
    },

    {
        path     : setLocalePath('/echipa'),
        exact    : true,
        component: Team
    },

    {
        path     : setLocalePath('/presa'),
        exact    : true,
        component: Media
    },

    {
        path     : setLocalePath('/rapoarte-anuale'),
        exact    : true,
        component: AnnualReports
    },

    {
        path     : setLocalePath('/contact'),
        exact    : true,
        component: Contact
    },

    // ----------------------------------------------------------------------------
    // No Match
    // ----------------------------------------------------------------------------
    {
        path     : '',
        exact    : true,
        component: NoMatch
    }
];

export default routes;
