import React from 'react';

import {Col, Container, Row} from 'react-bootstrap';
import {useTranslation}      from 'react-i18next';

import Footer     from '../../components/footer';
import PageHeader from '../../components/page-header';

import './Media.scss';


const Media = props => {
    const {t} = useTranslation();

    return (
        <section className="Media">
            <PageHeader/>

            <Container>
                <Row>
                    <Col xs={12}>
                        <h3>{t('media.title')}</h3>
                        <p>{t('media.empty_content')}</p>
                    </Col>
                </Row>
            </Container>

            <Footer/>
        </section>
    );
};

export default Media;
