import React from 'react';

import {useTranslation} from 'react-i18next';

import Navigation    from '../navigation';
import './Header.scss';

const Header =  props => {
    const {t} = useTranslation();

    return (
        <section className="Header">
            <Navigation isHome={props.isHome} t={t}/>
            {props.children}
        </section>
    );
};

export default Header;
