import React from 'react';

import {Container, Row, Col} from 'react-bootstrap';
import {useTranslation}      from 'react-i18next';

import './About.scss';


const About = () => {
    const {t} = useTranslation();

    return (
        <section className="About">
            <Container>
                <Row className="middle-md">
                    <Col xs={12} lg={5} className="first-md">
                        <img src="/web/img/resources/play.svg" alt=""/>
                    </Col>

                    <Col xs={12} lg={7} className="first-xs">
                        <div className="title">
                            <h2>{t('about.title')}</h2>
                            <h1>{t('about.who.title')}</h1>
                            <p><span dangerouslySetInnerHTML={{__html: t('about.who.text')}}/></p>
                        </div>

                        <div className="title">
                            <h1>{t('about.why.title')}</h1>
                            <p><span dangerouslySetInnerHTML={{__html: t('about.why.text')}}/></p>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container className="mt-5">
                <Row>
                    <Col xs={12}>
                        <div className="title">
                            <h1>{t('about.how.title')}</h1>
                            <p><span dangerouslySetInnerHTML={{__html: t('about.how.text')}}/></p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default About;
