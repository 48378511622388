import {combineReducers} from 'redux';

import Lang         from './Lang';
import persistStore from './persistStore';

const rootReducer = combineReducers({
    lang: Lang,
    persistStore
});

export default rootReducer;
