import React from "react";

import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Element, scroller } from "react-scroll";

import Features from "../../components/features/Features";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Partners from "../../components/partners";
import Beneficiaries from "../../components/beneficiaries";
import About from "./components/about";
import Donations from "./components/donations";
import Involve from "./components/involve";

import "./Home.scss";
import Sponsors from "../../components/sponsors";

const Home = props => {
    const scrollToElement = ev => {
        ev.preventDefault();

        scroller.scrollTo("doneaza", {
            smooth: true,
            hashSpy: true,
            offset: 20
        });
    };

    const { t } = useTranslation();

    return (
        <section className="Home">
            <Element name="acasa">
                <Header isHome={true} {...props}>
                    <Container>
                        <Row className="middle-md">
                            <Col xs={12} md={6}>
                                <div className="content">
                                    <h1>{t("header.title")}</h1>
                                    <p className="lead">
                                        {t("header.paragraph")}
                                    </p>

                                    <Button
                                        onClick={scrollToElement}
                                        variant="secondary"
                                    >
                                        {t("header.button.text")}
                                    </Button>
                                </div>
                            </Col>

                            <Col xs={12} md={6}>
                                <img
                                    src="/web/img/resources/doctors_2.svg"
                                    className="doctors"
                                    alt="Doctori"
                                />
                            </Col>
                        </Row>
                    </Container>
                </Header>

                <Features {...props} />
            </Element>

            <Element name="despre-noi">
                <About {...props} />
            </Element>

            <Element name="implica-te">
                <Involve {...props} />
            </Element>

            <Element name="doneaza">
                <Donations {...props} />
            </Element>

            <Partners {...props} />

            <Sponsors {...props} />

            <Beneficiaries {...props} />

            <Footer {...props} />
        </section>
    );
};

export default Home;
