import {connect} from 'react-redux';

import Navigation from './Navigation';

const mapStateToProps = state => {
    return {
        lang: state.lang.lang,
    };
};

export default connect(mapStateToProps)(Navigation);
