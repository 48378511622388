import * as ActionTypes from '../action-types';

export const langCheck = () => {
    return {
        type: ActionTypes.LANG_CHECK
    };
};

export const setLang = payload => {
    return {
        type: ActionTypes.LANG_SET,
        payload
    };
};
