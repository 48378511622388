import {connect} from 'react-redux';

import LanguageSelect from './LanguageSelect';

const mapStateToProps = state => {
    return {
        lang: state.lang.lang,
    };
};

export default connect(mapStateToProps)(LanguageSelect);
