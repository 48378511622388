import React, { Fragment, useState } from "react";

import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Modal,
    Row
} from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import "./Involve.scss";

const Involve = () => {
    const [form, setForm] = useState({
        first_name: "",
        last_name: "",
        email: "",
        message: "",
        domain: 0,
        type: "involve"
    });

    const [modalContent, setModalContent] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [selectIsInvalid, setSelectIsInvalid] = useState(false);

    const { t } = useTranslation();

    const handleInputChange = ev => {
        const name = ev.currentTarget.name;

        if (name) {
            setForm({
                ...form,
                [name]: ev.currentTarget.value
            });
        }

        if (name === "domain" && ev.currentTarget.value) {
            setSelectIsInvalid(false);
        }
    };

    const handleFormSubmit = ev => {
        ev.preventDefault();

        const theForm = ev.currentTarget;

        if (!theForm.checkValidity()) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        if (form.domain === 0) {
            setSelectIsInvalid(true);
        }

        setValidated(true);

        if (theForm.checkValidity()) {
            setIsLoading(true);
            fetch(
                "https://tfh-serverless.netlify.app/.netlify/functions/send-email-volunteer",
                {
                    method: "POST",
                    body: JSON.stringify(form)
                }
            )
                .then(response => {
                    setIsLoading(false);
                    handleModal();
                    if (response.message === "Success") {
                        toast.success(t("form.success"));
                    } else {
                        toast.error(t("form.error"));
                    }
                })
                .catch(({ error }) => console.log(error));
        }
    };

    const handleShowModal = type => {
        const content = {
            title: t(`involve.modal.${type}.title`),
            body: t(`involve.modal.${type}.body`)
        };

        setModalContent(content);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <Fragment>
            <section className="Involve">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="title">
                                <h2>{t("involve.entry")}</h2>
                                <h1>{t("involve.title")}</h1>
                                <p>{t("involve.subtitle")}</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="middle-md">
                        <Col xs={6} md={4}>
                            <Card
                                className="specialist"
                                onClick={() => handleShowModal("medics")}
                            >
                                <Card.Body>
                                    <Card.Img src="/web/img/resources/medic.png" />
                                    <Card.Text>{t("involve.medics")}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xs={6} md={4}>
                            <Card
                                className="specialist"
                                onClick={() => handleShowModal("translators")}
                            >
                                <Card.Body>
                                    <Card.Img src="/web/img/resources/translator.png" />
                                    <Card.Text>
                                        {t("involve.translators")}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xs={6} md={4}>
                            <Card
                                className="specialist"
                                onClick={() => handleShowModal("fundraisers")}
                            >
                                <Card.Body>
                                    <Card.Img src="/web/img/resources/fundraiser.png" />
                                    <Card.Text>
                                        {t("involve.fundraisers")}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xs={6}>
                            <Card
                                className="specialist"
                                onClick={() => handleShowModal("marketing")}
                            >
                                <Card.Body>
                                    <Card.Img src="/web/img/resources/social.png" />
                                    <Card.Text>
                                        {t("involve.marketing.specialists")}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xs={6}>
                            <Card
                                className="specialist"
                                onClick={() =>
                                    handleShowModal("administrative")
                                }
                            >
                                <Card.Body>
                                    <Card.Img src="/web/img/resources/administrativ.png" />
                                    <Card.Text>
                                        {t("involve.administrative.personnel")}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Modal
                            show={showModal}
                            onHide={handleCloseModal}
                            size="xl"
                            className="involve-modal"
                            aria-labelledby="contained-modal-title-vcenter"
                            backdrop="static"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title
                                    dangerouslySetInnerHTML={{
                                        __html: modalContent.title
                                    }}
                                />
                            </Modal.Header>
                            <Modal.Body>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: modalContent.body
                                    }}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="secondary"
                                    onClick={handleCloseModal}
                                >
                                    {t("involve.modal.close")}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Row>
                </Container>
            </section>

            <section className="Volunteer">
                <Container>
                    <Row className="middle-md">
                        <Col xs={12} lg={6}>
                            <div className="title">
                                <h1>{t("involve.form.title")}</h1>
                            </div>

                            <div className="step">
                                <div className="number">1</div>
                                <div className="content">
                                    <p>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: t("involve.step.one")
                                            }}
                                        />
                                    </p>
                                </div>
                            </div>

                            <div className="step">
                                <div className="number">2</div>
                                <div className="content">
                                    <p>{t("involve.step.two")}</p>
                                </div>
                            </div>

                            <div className="step">
                                <div className="number">3</div>
                                <div className="content">
                                    <p>{t("involve.step.three")}</p>
                                </div>
                            </div>

                            <p>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t("involve.extra")
                                    }}
                                />
                            </p>
                        </Col>

                        <Col xs={12} lg={6}>
                            <Card bg="light">
                                <Card.Body>
                                    <Form
                                        className="light-form"
                                        noValidate={true}
                                        validated={validated}
                                        onSubmit={handleFormSubmit}
                                        autoComplete="off"
                                    >
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Form.Group controlId="first-name">
                                                    <Form.Label>
                                                        {t(
                                                            "involve.form.first_name"
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="first_name"
                                                        required={true}
                                                        value={form.first_name}
                                                        disabled={isLoading}
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        autoComplete="new-first-names"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t(
                                                            "form.error.first_name"
                                                        )}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>

                                            <Col xs={12} md={6}>
                                                <Form.Group controlId="last-name">
                                                    <Form.Label>
                                                        {t(
                                                            "involve.form.last_name"
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="last_name"
                                                        required={true}
                                                        value={form.last_name}
                                                        disabled={isLoading}
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        autoComplete="new-last-names"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t(
                                                            "form.error.last_name"
                                                        )}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12}>
                                                <Form.Group controlId="email">
                                                    <Form.Label>
                                                        {t(
                                                            "involve.form.email"
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        name="email"
                                                        required={true}
                                                        value={form.email}
                                                        disabled={isLoading}
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        autoComplete="new-emails"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t("form.error.email")}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group controlId="domain">
                                                    <Form.Label>
                                                        {t(
                                                            "involve.form.field"
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        defaultValue={
                                                            form.domain
                                                        }
                                                        isInvalid={
                                                            selectIsInvalid
                                                        }
                                                        name="domain"
                                                        required={true}
                                                        disabled={isLoading}
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        autoComplete="new-domains"
                                                    >
                                                        <option
                                                            value={0}
                                                            disabled={true}
                                                        >
                                                            {t(
                                                                "involve.form.select.default"
                                                            )}
                                                        </option>
                                                        <option value="medicină">
                                                            {t(
                                                                "involve.form.select.medicine"
                                                            )}
                                                        </option>
                                                        <option value="traduceri">
                                                            {t(
                                                                "involve.form.select.translations"
                                                            )}
                                                        </option>
                                                        <option value="fundraising">
                                                            {t(
                                                                "involve.form.select.fundraising"
                                                            )}
                                                        </option>
                                                        <option value="marketing">
                                                            {t(
                                                                "involve.form.select.marketing"
                                                            )}
                                                        </option>
                                                        <option value="administrativ">
                                                            {t(
                                                                "involve.form.select.administrative"
                                                            )}
                                                        </option>
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        {t("form.error.field")}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group controlId="message">
                                                    <Form.Label>
                                                        {t(
                                                            "involve.form.message"
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows="3"
                                                        name="message"
                                                        required={true}
                                                        value={form.message}
                                                        disabled={isLoading}
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t(
                                                            "form.error.message"
                                                        )}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Button
                                                    type="submit"
                                                    variant="secondary"
                                                >
                                                    {isLoading
                                                        ? t("form.sending")
                                                        : t(
                                                              "involve.form.button.text"
                                                          )}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
};

export default Involve;
