import React            from 'react';
import {Route}          from 'react-router';
import {ToastContainer} from 'react-toastify';

import App from '../containers/app';


const PublicRoute = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={props => (
            <App>
                <Component {...props} />

                <ToastContainer/>
            </App>
        )}
    />
);

export default PublicRoute;
