import React from 'react';

import {Col, Container, Row} from 'react-bootstrap';

import Footer     from '../../components/footer';
import PageHeader from '../../components/page-header';

import './NoMatch.scss';


const NoMatch = () => {
    return (
        <section className="NoMatch">
            <PageHeader/>

            <Container>
                <Row>
                    <Col xs={12}>
                        <h1>404 | Not Found</h1>
                    </Col>
                </Row>
            </Container>

            <Footer/>
        </section>
    );
};

export default NoMatch;
