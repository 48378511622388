import React from 'react';

import {Container, Row, Col, Card} from 'react-bootstrap';
import {useTranslation}            from 'react-i18next';

import './Features.scss';


const Features = () => {
    const {t} = useTranslation();

    return (
        <section className="Features">
            <Container>
                <Row>
                    <Col xs={12} lg={4}>
                        <Card bg="dark">
                            <Card.Body className="blue-200">
                                <Card.Title>{t('features.vision.title')}</Card.Title>
                                <Card.Text>{t('features.vision.text')}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col xs={12} lg={4} className="first-md">
                        <Card bg="primary">
                            <Card.Body className="blue-100">
                                <Card.Title>{t('features.mission.title')}</Card.Title>
                                <Card.Text>{t('features.mission.text')}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col xs={12} lg={4}>
                        <Card bg="secondary">
                            <Card.Body className="pink-100">
                                <Card.Title>{t('features.values.title')}</Card.Title>
                                <ul>
                                    <span dangerouslySetInnerHTML={{__html: t('features.values.text')}}/>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Features;
