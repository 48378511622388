import React, {useCallback, useEffect, useState} from 'react';

import {Container, Nav, Navbar} from 'react-bootstrap';
import {useTranslation}         from 'react-i18next';
import {Link}                   from 'react-router-dom';
import {animateScroll}          from 'react-scroll/modules';

import Icon             from '../icon';
import LanguageSelect   from '../language-select';
import useEventListener from '../../hooks/useEventListener';

import '../navigation/Navigation.scss';
import './PageHeader.scss';


const PageHeader = props => {
    useEffect(() => {
        // animateScroll.scrollToTop();
        window.scrollTo({top: 0});
    }, []);

    const [scroll, setScroll] = useState(0);
    const [top, setTop]       = useState(0);

    const handler = useCallback(() => {
        setScroll(window.scrollY);
    }, [setScroll]);

    useEventListener('scroll', handler);

    useEffect(
        () => {
            const el = document.querySelector('nav');

            if (el) {
                setTop(el.offsetTop);

                if (scroll > top) {
                    document.body.className = 'nav-fixed';
                } else {
                    document.body.style.paddingTop = null;
                    document.body.className        = '';
                }
            }
        },
        [handler, scroll, top]
    );

    const isFixed = () => {
        if (scroll > top) {
            return 'fixed-top';
        }
    };

    const {t} = useTranslation();

    return (
        <section className="PageHeader">
            <section className="Navigation">
                <Navbar
                    collapseOnSelect={true}
                    expand="lg"
                    className={isFixed()}
                >
                    <Container>
                        <Navbar.Brand href="/">
                            <img
                                src="/web/img/logo/logo.svg"
                                alt="Translating For Humanity"
                            />
                        </Navbar.Brand>

                        <LanguageSelect show="all"/>

                        <Nav>
                            <Link to={(props.lang === 'en' ? `/en` : '/')} className="nav-link has-icon left">
                                <Icon size="light" icon="chevron-left"/>
                                {t('nav.home')}
                            </Link>
                        </Nav>
                    </Container>
                </Navbar>
            </section>
            {props.children}
        </section>
    );
};

export default PageHeader;
