import React           from 'react';
import {Switch}        from 'react-router';
import {BrowserRouter} from 'react-router-dom';

import routes from './routes';

import PublicRoute from './Public';

const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                {routes.map((route, i) => {
                    return <PublicRoute key={i} {...route} />;
                })}
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;
