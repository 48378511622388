import React from 'react';

import {Col, Container, Row} from 'react-bootstrap';
import {useTranslation}      from 'react-i18next';
import {Link}                from 'react-router-dom';

import './Footer.scss';


const Footer = props => {
    const {t} = useTranslation();

    return (
        <section className="Footer">
            <Container>
                <Row>
                    <Col xs={12}>
                        <nav className="nav">
                            <li>
                                <Link
                                    to={(
                                        props.lang === 'en'
                                            ? `/en/echipa`
                                            : '/echipa'
                                    )}>
                                    {t('footer.nav.team')}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={(
                                        props.lang === 'en'
                                        ? `/en/politica-de-confidentialitate`
                                        : '/politica-de-confidentialitate'
                                    )}>
                                    {t('footer.nav.policy')}
                                </Link>
                            </li>
                            <li>
                                <Link to={(
                                    props.lang === 'en'
                                    ? '/en/presa' : '/presa'
                                )}>
                                    {t('footer.nav.media')}
                                </Link>
                            </li>
                            <li>
                                <Link to={(
                                    props.lang === 'en'
                                    ? '/en/rapoarte-anuale'
                                    : 'rapoarte-anuale'
                                )}>
                                    {t('footer.nav.reports')}
                                </Link>
                            </li>
                        </nav>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="bottom-footer">
                            <div className="copyright">
                                <div>
                                    &copy; {new Date().getFullYear()}{' '}
                                    <span className="text-secondary font-weight-bold">
                                        Asociația Translating for Humanity.
                                    </span>
                                    <span>{t('footer.copyright')}</span>
                                </div>
                                <div className="developer"><span
                                    dangerouslySetInnerHTML={{__html: t('footer.developer')}}/></div>
                            </div>

                            <div className="social">
                                <a href="https://www.facebook.com/translatingforhumanity" target="_blank"
                                   rel="noopener noreferrer">
                                    <i className="fab fa-facebook-f"/>
                                </a>
                                <a href="https://twitter.com/TranslatingFH" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-twitter"/>
                                </a>
                                <a href="http://linkedin.com/company/translating-for-humanity" target="_blank"
                                   rel="noopener noreferrer">
                                    <i className="fab fa-linkedin"/>
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Footer;
