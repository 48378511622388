import React from 'react';

const iconWeight = value => {
    switch (value) {
        case 'light':
            return 'fal';
        case 'regular':
            return 'far';
        case 'solid':
            return 'fas';
        case 'brand':
            return 'fab';

        default:
            return 'far';
    }
};

const Icon = props => {
    return <i className={iconWeight(props.size) + ' fa-' + props.icon} />;
};

export default Icon;
