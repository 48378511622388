import React, {useEffect, useState} from 'react';

import {animateScroll} from 'react-scroll';

import Icon from '../icon';

import './ScrollToTop.scss';


const ScrollToTop = () => {
    const [show, setShow]       = useState(false);
    const [windowY, setWindowY] = useState(0);
    const [windowH, setWindowH] = useState(0);

    useEffect(() => {
        const checkPosition = () => {
            setWindowY(window.scrollY);
            setWindowH(window.innerHeight);

            if (windowY < windowH / 2) {
                setShow(false);
            } else {
                setShow(true);
            }
        };

        window.addEventListener('scroll', checkPosition);

        return () => window.removeEventListener('scroll', checkPosition);
    }, [windowY, windowH]);

    const scrollToTop = () => {
        animateScroll.scrollToTop();
    };

    return (
        <div className={'ScrollToTop' + (show ? ' show' : '')} onClick={scrollToTop}>
            <Icon size="light" icon="chevron-up"/>
        </div>
    );
};

export default ScrollToTop;
