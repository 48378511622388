import {applyMiddleware, createStore} from 'redux';
import logger                         from 'redux-logger';
import {composeWithDevTools}          from 'redux-devtools-extension';
import {persistStore}                 from 'redux-persist';
import thunkMiddleware                from 'redux-thunk';

import rootReducer from './reducers';

const store = () => {
    const middleWares = [thunkMiddleware];

    let middleWareEnhancer;
    if (process.env.NODE_ENV === 'development') {
        middleWareEnhancer = applyMiddleware(...middleWares, logger);
    } else {
        middleWareEnhancer = applyMiddleware(...middleWares);
    }

    const configureStore = createStore(
        rootReducer,
        composeWithDevTools(middleWareEnhancer)
    );

    persistStore(configureStore);

    return configureStore;
};

export default store();
