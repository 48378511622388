import i18n               from 'i18next';
import {initReactI18next} from 'react-i18next';

import en from './translations/en.json';
import ro from './translations/ro.json';

const setLang = () => {
    const lang = localStorage.getItem('lang');

    if (!lang) {
        return 'ro';
    }

    return lang;
};

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: en
    },
    ro: {
        translation: ro
    }
};

// tslint:disable-next-line: no-floating-promises
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: setLang(),

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
