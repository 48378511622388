import axios from 'axios';

axios.defaults.headers.common = {
    'Accept'                          : 'application/json',
    'Content-Type'                    : 'application/json',
    // 'X-Requested-With'                : 'XMLHttpRequest',
    // 'Access-Control-Allow-Credentials': false,
};

axios.defaults.baseURL         = window.API_URL;
axios.defaults.withCredentials = false;

export default axios;
