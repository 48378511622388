import React, { useState } from "react";

import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import "./Contact.scss";
import PageHeader from "../../components/page-header";
import Footer from "../../components/footer";
import Icon from "../../components/icon";

const Contact = () => {
    const [form, setForm] = useState({
        first_name: "",
        last_name: "",
        email: "",
        subject: "",
        message: "",
        type: "contact"
    });

    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [success, setSuccess] = useState(false);

    const clearForm = () => {
        setForm({
            ...form,
            first_name: "",
            last_name: "",
            email: "",
            subject: "",
            message: ""
        });

        setValidated(false);
    };

    const handleInputChange = ev => {
        const name = ev.currentTarget.name;

        if (name) {
            setForm({
                ...form,
                [name]: ev.currentTarget.value
            });
        }
    };

    const { t } = useTranslation();

    const handleFormSubmit = ev => {
        ev.preventDefault();

        const theForm = ev.currentTarget;

        if (!theForm.checkValidity()) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        setValidated(true);

        if (theForm.checkValidity()) {
            setIsLoading(true);
            fetch(
                "https://tfh-serverless.netlify.app/.netlify/functions/send-email",
                {
                    method: "POST",
                    body: JSON.stringify(form)
                }
            )
                .then(res => res.json())
                .then(response => {
                    setIsLoading(false);
                    handleModal();
                    if (response.message === "Success") {
                        toast.success(t("form.success"));
                    } else {
                        toast.error(t("form.error"));
                    }
                })

                .catch(({ error }) => console.log(error));
        }
    };

    const showForm = () => {
        setSuccess(false);
    };

    return (
        <section className="Contact">
            <PageHeader />

            <Container>
                <h2>Contact</h2>

                <br />

                <Row>
                    <Col xs={12} lg={4}>
                        <div className="bank-donations">
                            <p>
                                <strong>Email:</strong>{" "}
                                <a href="mailto:contact@translatingforhumanity.com">
                                    contact@translatingforhumanity.com
                                </a>
                            </p>
                            <p>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t("donate.ong_data.telephone")
                                    }}
                                />
                            </p>
                            <p>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t("donate.ong_data.name")
                                    }}
                                />
                            </p>
                            <p>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t("donate.ong_data.raf")
                                    }}
                                />
                            </p>
                            <p>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t("donate.ong_data.cif")
                                    }}
                                />
                            </p>
                            <p>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t("donate.ong_data.address")
                                    }}
                                />
                            </p>
                            <p>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t("donate.ong_data.iban")
                                    }}
                                />
                            </p>
                        </div>
                    </Col>

                    <Col xs={12} lg={8}>
                        <Card bg="light">
                            <Form
                                className="light-form"
                                noValidate={true}
                                validated={validated}
                                onSubmit={handleFormSubmit}
                                autoComplete="off"
                            >
                                {!isLoading && !success && (
                                    <Card.Body>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Form.Group controlId="first-name">
                                                    <Form.Label>
                                                        {t(
                                                            "contact.form.first_name"
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="first_name"
                                                        required={true}
                                                        value={form.first_name}
                                                        disabled={isLoading}
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        autoComplete="new-first-names"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t(
                                                            "form.error.first_name"
                                                        )}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>

                                            <Col xs={12} md={6}>
                                                <Form.Group controlId="last-name">
                                                    <Form.Label>
                                                        {t(
                                                            "contact.form.last_name"
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="last_name"
                                                        required={true}
                                                        value={form.last_name}
                                                        disabled={isLoading}
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        autoComplete="new-last-names"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t(
                                                            "form.error.last_name"
                                                        )}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>

                                            <Col xs={12} md={6}>
                                                <Form.Group controlId="email">
                                                    <Form.Label>
                                                        {t(
                                                            "contact.form.email"
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        name="email"
                                                        required={true}
                                                        value={form.email}
                                                        disabled={isLoading}
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        autoComplete="new-emails"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t("form.error.email")}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>

                                            <Col xs={12} md={6}>
                                                <Form.Group controlId="subject">
                                                    <Form.Label>
                                                        {t(
                                                            "contact.form.subject"
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control
                                                        name="subject"
                                                        required={true}
                                                        value={form.subject}
                                                        disabled={isLoading}
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        autoComplete="new-subject"
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t(
                                                            "form.error.subject"
                                                        )}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>

                                            <Col xs={12}>
                                                <Form.Group controlId="message">
                                                    <Form.Label>
                                                        {t(
                                                            "contact.form.message"
                                                        )}
                                                    </Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows="3"
                                                        name="message"
                                                        required={true}
                                                        value={form.message}
                                                        disabled={isLoading}
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t(
                                                            "form.error.message"
                                                        )}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12}>
                                                <Button
                                                    type="submit"
                                                    variant="secondary"
                                                >
                                                    {isLoading
                                                        ? t("form.sending")
                                                        : t(
                                                              "involve.form.button.text"
                                                          )}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                )}

                                {isLoading && !success && (
                                    <Card.Body className="sending">
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: t(
                                                    "contact.form.sending"
                                                )
                                            }}
                                        />
                                        <Icon
                                            size="light"
                                            icon="spinner fa-spin"
                                        />
                                    </Card.Body>
                                )}

                                {!isLoading && success && (
                                    <Card.Body className="sending">
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: t(
                                                    "contact.form.success"
                                                )
                                            }}
                                        />
                                        <Icon
                                            size="light"
                                            icon="check blue-400"
                                        />
                                        <Button
                                            className="ml-auto mr-auto mt-4"
                                            type="button"
                                            onClick={showForm}
                                            variant="secondary"
                                        >
                                            Ok
                                        </Button>
                                    </Card.Body>
                                )}
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </section>
    );
};

export default Contact;
