import React from 'react';

import {Form, FormCheck} from 'react-bootstrap';

import store       from '../../store';
import * as action from '../../store/actions';

import './LanguageSelect.scss';


const LanguageSelect = props => {
    const handleLangChange = ev => {
        store.dispatch(action.setLang({lang: ev.target.value}));

        let url;
        if (ev.target.value === 'en') {
            url = window.location.origin + '/en' + window.location.pathname;
        } else {
            url = window.location.href.replace('/en', '');
        }

        window.location.href = url;
    };

    const showOnDevice = () => {
        if (props.show === 'all') return 'mobile desktop';

        return props.show;
    };

    return (
        <div className={'lang-flag ' + showOnDevice()}>
            <Form.Check inline={true}>
                <FormCheck.Input
                    type="radio"
                    value="en"
                    id="en-lang"
                    checked={props.lang === 'en'}
                    onChange={handleLangChange}
                />
                <FormCheck.Label
                    htmlFor="en-lang"
                >
                    <img src="/web/img/flags/gb.svg" alt="English"/>
                </FormCheck.Label>
            </Form.Check>

            <Form.Check inline={true}>
                <FormCheck.Input
                    type="radio"
                    value="ro"
                    id="ro-lang"
                    checked={props.lang === 'ro'}
                    onChange={handleLangChange}
                />
                <FormCheck.Label
                    htmlFor="ro-lang"
                >
                    <img src="/web/img/flags/ro.svg" alt="Romanian"/>
                </FormCheck.Label>
            </Form.Check>
        </div>
    );
};

export default LanguageSelect;
