import React, {Fragment, useState} from 'react';

import {Button, Col, Container, Form, Modal, Row} from 'react-bootstrap';

import {useTranslation} from 'react-i18next';
import {toast}          from 'react-toastify';

import {ContactService}     from '../../services';
import Icon                 from '../icon';

import './Contact.scss';


const Contact = props => {
    const [form, setForm] = useState({
        first_name: '',
        last_name : '',
        email     : '',
        subject   : '',
        message   : '',
        type      : 'contact'
    });

    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);

    const handleModal = () => {
        setForm({
            ...form,
            first_name: '',
            last_name : '',
            email     : '',
            subject   : '',
            message   : ''
        });

        setValidated(false);
        props.onHide();
    };

    const handleInputChange = ev => {
        const name = ev.currentTarget.name;

        if (name) {
            setForm({
                ...form,
                [name]: ev.currentTarget.value
            });
        }
    };

    const {t} = useTranslation();

    const handleFormSubmit = ev => {
        ev.preventDefault();

        const theForm = ev.currentTarget;
        if (!theForm.checkValidity()) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        setValidated(true);
        setIsLoading(true);

        fetch("https://tfh-serverless.netlify.app/.netlify/functions/send-email", {
            method: "POST",
            body: JSON.stringify(form),
          })
        .then((res) => res.json())
        .finally(() => {
            setIsLoading(false);
            handleModal();
            toast.success(t('form.success'));
        })
        .catch(({error}) => console.log(error));


        // ContactService.sendMessage(form)
        //     .then()
        //     .finally(() => {
        //         setIsLoading(false);
        //         handleModal();
        //         toast.success(t('form.success'));
        //     })
        //     .catch(({error}) => console.log(error));
    };

    return (
        <Modal
            show={props.show}
            onHide={handleModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered={true}
            keyboard={false}
            backdrop="static"
            size={isLoading ? 'sm' : 'lg'}
            className="modal-dark"
        >
            {!isLoading ? (
                <Fragment>
                    <Modal.Header closeButton={!isLoading}>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Contact
                        </Modal.Title>
                    </Modal.Header>

                    <Form
                        className="dark-form"
                        noValidate={true}
                        validated={validated}
                        onSubmit={handleFormSubmit}
                        autoComplete="off"
                    >
                        <Modal.Body>
                            <Container>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <Form.Group controlId='first-name'>
                                            <Form.Label>Nume*</Form.Label>
                                            <Form.Control
                                                name="first_name"
                                                required={true}
                                                value={form.first_name}
                                                disabled={isLoading}
                                                onChange={handleInputChange}
                                                autoComplete="new-first-names"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Câmpul nume este obligatoriu.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} md={6}>
                                        <Form.Group controlId="last-name">
                                            <Form.Label>Prenume*</Form.Label>
                                            <Form.Control
                                                name="last_name"
                                                required={true}
                                                value={form.last_name}
                                                disabled={isLoading}
                                                onChange={handleInputChange}
                                                autoComplete="new-last-names"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Câmpul prenume este obligatoriu.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} md={6}>
                                        <Form.Group controlId="email">
                                            <Form.Label>Email*</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                required={true}
                                                value={form.email}
                                                disabled={isLoading}
                                                onChange={handleInputChange}
                                                autoComplete="new-emails"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Câmpul email este obligatoriu.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} md={6}>
                                        <Form.Group controlId="subject">
                                            <Form.Label>Subiect*</Form.Label>
                                            <Form.Control
                                                name="subject"
                                                required={true}
                                                value={form.subject}
                                                disabled={isLoading}
                                                onChange={handleInputChange}
                                                autoComplete="new-subject"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Câmpul subiect este obligatoriu.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12}>
                                        <Form.Group controlId="message">
                                            <Form.Label>Mesaj*</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows="3"
                                                name="message"
                                                required={true}
                                                value={form.message}
                                                disabled={isLoading}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Câmpul mesaj este obligatoriu.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" variant="secondary">
                                Trimite
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Fragment>
            ) : (
                 <div className="form-sending dark">
                     <Icon size="light" icon="spinner fa-spin"/>
                     <p>{t('form.sending')}</p>
                 </div>
             )}
        </Modal>
    );
};

export default Contact;
