import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "./Sponsors.scss";

const Sponsors = () => {
    const { t } = useTranslation();

    return (
        <section className="Sponsors">
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="title">
                            <h1>{t("sponsors.title")}</h1>
                            <p>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t("sponsors.subtitle")
                                    }}
                                />
                            </p>
                        </div>
                    </Col>
                </Row>

                <Row className="middle-md logos">
                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://www.greenhorsegames.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/sponsors/ghg-color.svg"
                                alt="Green Horse Games"
                            />
                        </a>
                    </Col>

                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://sightcontrol.ro/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/sponsors/sight-control.png"
                                alt="Sus Inima"
                            />
                        </a>
                    </Col>

                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://anais.digital/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/sponsors/anais-digital.png"
                                alt="Asociația Magic"
                            />
                        </a>
                    </Col>

                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://www.lzagroup.ro/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/sponsors/lza-group.png"
                                alt="Magic Help"
                            />
                        </a>
                    </Col>

                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://casadetraduceri.ro/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/sponsors/casa-traduceri.png"
                                alt="Casa de Traduceri"
                            />
                        </a>
                    </Col>
                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://andrapostolache.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/sponsors/andra-postolache.png"
                                alt="Andra Postolache"
                            />
                        </a>
                    </Col>
                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://www.wetranslate.ro/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/sponsors/wt-logo-print.svg"
                                alt="WeTranslate"
                            />
                        </a>
                    </Col>
                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="http://zynala.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/sponsors/zynala-logo.png"
                                alt="Zynala"
                            />
                        </a>
                    </Col>
                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://www.memoq.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/sponsors/memoq-logo_full-color_.png"
                                alt="memoq"
                            />
                        </a>
                    </Col>
                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://www.wordpower.ro/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/sponsors/words-with-power.png"
                                alt="wordsWithPower"
                            />
                        </a>
                    </Col>
                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://www.commons.work/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/sponsors/logo-commons.jpeg"
                                alt="Commons"
                            />
                        </a>
                    </Col>
                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="https://www.elbowstore.ro/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/sponsors/elbowStore.png"
                                alt="ElbowStore"
                            />
                        </a>
                    </Col>
                    <Col xs={6} md={4} lg={3}>
                        <a
                            href="http://www.traduceriautorizateiasi.ro/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/web/img/sponsors/teamTRADis.jpg"
                                alt="TeamTRADis"
                            />
                        </a>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Sponsors;
