import i18n from 'i18next';

export const getLocale = () => {
    const serverLocale = window.LOCALE;
    let localLocale;

    if (serverLocale === 'en') {
        localStorage.setItem('lang', 'en');
        localLocale = 'en';
    } else {
        localStorage.setItem('lang', 'ro');
        localLocale = 'ro';
    }

    i18n.changeLanguage(localLocale);

    return localLocale;
};
